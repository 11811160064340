<template>
  <div class="register-wrap">
    <a-card class="light-shadow" :bordered="false">
      <template #title>
        <router-link to="/">
          <a-avatar
            shape="square"
            size="small"
            src="/images/logo.png"
            class="mr-2"
          ></a-avatar>
        </router-link>
        <span>{{ $t("account.forget_password") }}</span>
      </template>
      <template #extra>
        <div class="langs-chooser">
          <LangChooser></LangChooser>
        </div>
      </template>
      <template> </template>
      <div class="register-form-wrap">
        <div class="register-form">
          <div class="text-center pb-4">
            <!-- <img src="/images/logo.png" style="height: 60px" /> -->
            <img v-if="isZhCn" src="@/assets/image/public/logo-4.png" style="height: 36px" />
            <img v-else src="@/assets/image/public/logo-3.png" style="height: 36px" />
          </div>
          <a-form
            v-show="stepIndex === 0"
            ref="formRef"
            :model="formData"
            :label-col="{ span: 6 }"
            :rules="rules"
            :hideRequiredMark="true"
          >
            <a-form-item
              :label="$t('account.registration_country')"
              name="countryId"
            >
              <a-select
                v-model:value="formData.countryId"
                :placeholder="$t('common.country')"
                :loading="formData.countryLoading"
                :showSearch="true"
                optionFilterProp="search-key"
              >
                <a-select-option
                  v-for="item in countries"
                  :key="item.id"
                  :value="item.id"
                  :search-key="item.cnName + item.enName+item.ioS2"
                  >{{ getLanguageName(item) }}({{item.ioS2}})</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item
              :label="$t('account.cell_phone_number')"
              name="phoneNumber"
            >
              <a-input v-model:value="formData.phoneNumber">
                <template #prefix>
                  <span v-if="telCode">+{{ telCode }}</span>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item
              :label="$t('account.enter_verification_code')"
              name="captchaCode"
            >
              <a-input v-model:value="formData.captchaCode" >
                <template #prefix>
                  <div class="validation-wrap">
                    <c-image
                      @click="refreshCapthImg"
                      v-if="formData.captchaImg"
                      class="validation-img"
                      :src="formData.captchaImg"
                      :preview="false"
                    ></c-image>
                  </div>
                </template>
              </a-input>
              <!-- <a-space style="width: 100%">
                <a-input v-model:value="formData.captchaCode" />
                <c-image
                  @click="refreshCapthImg"
                  v-if="formData.captchaImg"
                  class="validation-img"
                  :src="formData.captchaImg"
                  :preview="false"
                ></c-image>
              </a-space> -->
            </a-form-item>
            <a-row type="flex" justify="center">
              <a-button
                type="primary"
                @click="handleNext"
                :loading="formData.btnLoading"
                >{{ $t("menu.account_restpwd") }}</a-button
              >
            </a-row>
          </a-form>

          <a-form
            v-show="stepIndex === 1"
            ref="resetRef"
            :model="resetForm"
            :label-col="{ span: 6 }"
            :rules="resetRules"
            :hideRequiredMark="true"
          >
            <a-form-item :label="$t('account.registration_country')">
              <span>{{ getCountryName(resetForm.countryId) }}</span>
            </a-form-item>
            <a-form-item :label="$t('account.cell_phone_number')">
              <span>{{ resetForm.phone }}</span>
            </a-form-item>
            <a-form-item
              :label="$t('account.set_new_password')"
              name="newPassWord"
            >
              <a-input-password v-model:value="resetForm.newPassWord" />
            </a-form-item>
            <a-form-item
              :label="$t('account.enter_the_password_again')"
              name="reNewPassWord"
            >
              <a-input-password v-model:value="resetForm.reNewPassWord" />
            </a-form-item>
            <a-form-item
              :label="$t('account.sms_validation_code')"
              name="validCode"
            >
              <a-input v-model:value="resetForm.validCode" />
            </a-form-item>
            <a-row type="flex" justify="center">
              <a-button
                type="primary"
                @click="handleConfirm"
                :loading="formData.btnLoading"
                >{{ $t("common.confirm") }}</a-button
              >
            </a-row>
          </a-form>
        </div>
      </div>
      <div v-show="stepIndex === 0" style="text-align: right">
        <a-space>
          <router-link to="/login">{{
            $t("account.login_account")
          }}</router-link>
          <router-link to="/register">{{
            $t("account.register_new_users")
          }}</router-link>
        </a-space>
      </div>
    </a-card>
        <CEnvTips />
  </div>
</template>
<script>
import {
  Avatar,
  Form,
  Input,
  Row,
  Col,
  Button,
  Card,
  Switch,
  Dropdown,
  Menu,
  Select,
  Space,
  Spin,
  message,
} from "ant-design-vue";
import { reactive, onMounted, computed, ref, toRefs, toRaw } from "vue";
import CImage from "../components/CImage.vue";
import LangChooser from "../components/LangChooser.vue";
import { genCaptchaInfo, getCaptchaUrl } from "@/api/modules/captcha/index.js";
import {
  checkPhoneNumberIsExist,
  getForgetPwdCode,
  resetPassword,
} from "@/api/modules/account/index.js";
import { getCountrys, getTimezones } from "@/api/modules/common/index.js";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getName } from "@/utils/general";
import { useI18n } from "vue-i18n/index";
import CEnvTips from '@/views/components/CEnvTips.vue'
export default {
  name: "register",
  components: {
    AAvatar: Avatar,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AInputPassword: Input.Password,
    AInputGroup: Input.Group,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ACard: Card,
    ASwitch: Switch,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASpace: Space,
    ASpin: Spin,
    CImage,
    LangChooser,
    CEnvTips
  },
  setup() {
    const vueI18n = useI18n();
    const { dispatch, getters } = useStore();
    const router = useRouter();
    const formRef = ref();
    const resetRef = ref();

    const state = reactive({
      stepIndex: 0,
      countries: [],
      timeZones: [],
    });

    const formData = reactive({
      countryId: null,
      phoneNumber: null,
      validCode: null,
      captchaCode: null,

      btnLoading: false,

      captchaImg: null,

      captchaRequired: false,
      captchaId: null,

      timerSecond: null,
      smsSending: false,
    });

    const resetForm = reactive({
      countryId: "45",
      phone: "",
      newPassWord: "",
      reNewPassWord: "",
      validCode: "",
    });

    const telCode = computed(() => {
      if (formData.countryId && state.countries.length > 0) {
        let item = state.countries.find((x) => x.id == formData.countryId);
        return (item || {}).telCode;
      }
      return null;
    });

    const loadTimeZones = () => {
      getTimezones({}).then((res) => {
        state.timeZones = res.result || [];
      });
    };

    const loadCountries = () => {
      getCountrys().then((res) => {
        //state.countries = res.result;
        // 暂仅支持中国
        state.countries = res.result.filter((x) => x.ioS2 == "CN" || x.ioS2=="US");

        if (
          formData.countryId &&
          state.countries.findIndex((x) => x.id == formData.countryId) >= 0
        ) {
          //keep it
        } else {
          let selectedCountry = state.countries[0];
          if (selectedCountry) {
            formData.countryId = selectedCountry.id;
          } else {
            formData.countryId = null;
          }
        }
      });
    };

    const loadCaptchaInfo = () => {
      genCaptchaInfo().then(({ result }) => {
        formData.captchaId = result.guid;
        refreshCapthImg();
      });
    };

    const refreshCapthImg = () => {
      if (formData.captchaId) {
        formData.captchaImg = getCaptchaUrl(formData.captchaId);
      }
      formData.captchaCode = null;
    };

    const handleNext = async () => {
      formData.btnLoading = true;
      try {
        let formRes = await formRef.value.validate();
        let { result: isExist } = await checkPhoneNumberIsExist({
          phoneNumber: formRes.phoneNumber,
        });
        if (!isExist) {
          message.warning(vueI18n.t("account.mobile_phone_number_is_not_registered"));
          refreshCapthImg();
          return;
        }
        let countryInfo = state.countries.find(
          (item) => item.id === formRes.countryId
        );
        if (countryInfo) {
          let { result } = await getForgetPwdCode({
            phoneNumber: formRes.phoneNumber,
            guid: formData.captchaId,
            captchaCode: formRes.captchaCode,
            countryCode: countryInfo.telCode,
          });
          if (result) {
            message.success(vueI18n.t("account.SMS_sent_successfully"));
            resetForm.countryId = formRes.countryId;
            resetForm.phone = formRes.phoneNumber;
            state.stepIndex = 1;
          } else {
            message.success(vueI18n.t("account.SMS_sending_failed"));
          }
        }
      } catch (error) {
        refreshCapthImg();
      } finally {
        formData.btnLoading = false;
      }
    };

    const handleConfirm = async () => {
      try {
        await resetRef.value.validate();
        formData.btnLoading = true;
        let formRes = toRaw(resetForm)
        const { reNewPassWord, ...data } = formRes
        await resetPassword(data)
        message.success(vueI18n.t('account.change_password', [vueI18n.t('common.succeed')]))
        logout()
      } catch (error) {

      } finally {
        formData.btnLoading = false;
      }
    };

    const logout = async ()=>{
      await dispatch("user/logout")
      router.push("/login");
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const isZhCn = computed(()=>{
      return "zh-cn" == (getters.language||"").toLowerCase();;
    });

    const chianMainlandPhoneReg = new RegExp("^1[3456789]\\d{9}$");
    const rules = {
      countryId: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("account.registration_country"),
            ]);
          },
          trigger: ["change", "blur"],
        },
      ],
      phoneNumber: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("account.phone_number"),
            ]);
          },
          trigger: ["change", "blur"],
        },
        {
          validator: (rule, value) => {
            if (value) {
              if (chianMainlandPhoneReg.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject();
            }
            return Promise.resolve();
          },
          message: () => {
            return `${vueI18n.t(
              "account.phont_number_is_in_the_wrong_format"
            )}(${vueI18n.t("account.currently_only_supports_mainland_china")})`;
          },
          trigger: ["blur"],
        },
      ],
      captchaCode: [
        {
          validator: async (rule, value) => {
            if (value && value.length > 0) {
              return Promise.resolve();
            } else {
              return Promise.reject();
            }
          },
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("account.validation_code"),
            ]);
          },
          trigger: ["change", "blur"],
        },
      ],
    };

    const resetRules = {
      newPassWord: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("account.set_new_password"),
            ]);
          },
          trigger: ["change", "blur"],
        },
        {
          min: 6,
          message: () => {
            return vueI18n.t("common.p0_must_be_at_least_p1_characters", [
              vueI18n.t("account.set_new_password"),
              6,
            ]);
          },
          trigger: "blur",
        },
      ],
      reNewPassWord: [
        {
          required: true,
          validator: async (rule, value) => {
            if (resetForm.newPassWord == value) {
              return Promise.resolve();
            }
            return Promise.reject();
          },
          message: () => {
            return vueI18n.t("account.the_two_passwords_are_inconsistent");
          },
          trigger: ["change", "blur"],
        },
      ],
      validCode: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("account.sms_validation_code"),
            ]);
          },
          trigger: ["change", "blur"],
        },
      ],
    };

    const getCountryName = (countryId) => {
      let countryInfo = state.countries.find((item) => item.id === countryId);
      if (countryInfo) {
        return getters.language === "zh-CN"
          ? countryInfo.cnName
          : countryInfo.enName;
      }
      return "";
    };

    onMounted(() => {
      loadTimeZones();
      loadCountries();
      loadCaptchaInfo();
    });

    return {
      ...toRefs(state),
      formData,
      formRef,
      resetRef,
      telCode,
      resetForm,
      isZhCn,

      refreshCapthImg,
      getLanguageName,
      handleNext,
      getCountryName,
      handleConfirm,

      rules,
      resetRules,
    };
  },
};
</script>
<style lang="less">
.register-wrap {
  width: 600px;
  max-width: 100%;
}
.register-form-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  .register-form {
    width: 400px;
    max-width: 100%;
  }
}
.validation-wrap {
  margin-top: -4px;
  margin-bottom: -4px;
  margin-left: -10px;
  .validation-img {
    height: 30px;
    max-width: 100%;
    width: auto;
    border-radius: 4px 0 0 4px;
  }
}
</style>