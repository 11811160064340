import client from "../../client";
import siteOptions from "../../../siteconfigs/index";
import urlHelper from "../../../utils/urlHelper.js";

const Api = {
    //获取验证码的Key
    generateValidCodeCaptcha: "/api/BasicDataService/Captcha/GenerateValidCodeCaptcha",

    //验证码图片地址
    validCodeCaptchaFile: "/api/BasicDataService/Captcha/ValidCodeCaptchaFile",
};

//获取验证码信息
export const genCaptchaInfo = () => {
    return client.request({
        url: Api.generateValidCodeCaptcha,
        data: {},
        method: "post",
    });
}

//获取验证码图片地址
export const getCaptchaUrl = (id) => {
    let url = urlHelper.combineURLs(
        siteOptions.baseURL,
        Api.validCodeCaptchaFile
    );
    url = urlHelper.addQueryString(url, "id", id);
    url = urlHelper.addQueryString(url, "t", Math.random()+"");
    return url;
}